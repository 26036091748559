<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Customers from "@/services/Customers";
import TabPacksImages from "@/components/tabs/tabPacksImages";

export default {
    components: {
        Layout,
        PageHeader,
        TabPacksImages
    },
    page: {
        title: "Pack Details",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    data() {
        return {
            title: "Pack Details",
            items: [
                { text: "Collectibles" },
                { text: "Pack Details", active: true },
            ],
            packInfo: {},
            error: null,
            toggleProfile: false,
            showLoader: false,
            totalCollectibles: 0,
            totalClaimed: 0,
            totalImagePercent: 0,
            totalSingleTickets: 0,
            totalSeasonTickets: 0,
            totalTickets: 0,
        };
    },
    computed: {
        formattedTotalCollectibles() {
            return new Intl.NumberFormat('en-US').format(this.totalCollectibles);
        },
        formattedTotalTickets() {
            return new Intl.NumberFormat('en-US').format(this.totalTickets);
        },
    },
    async mounted() {
        this.getNftPackDetails();
    },

    methods: {
        getNftPackDetails() {
            this.showLoader = true;
            Customers.getNftPackDetails(this.$route.params.pack_id)
                .then((response) => {
                    this.packInfo = response.data;
                    this.totalCollectibles = response.data.total_collectibles || 0;
                    this.totalClaimed = response.data.total_claimed || 0;
                    this.totalImagePercent = response.data.image_percent || 0;
                    this.totalSingleTickets = response.data.total_single_tickets || 0;
                    this.totalSeasonTickets = response.data.total_season_tickets || 0;
                    this.totalTickets = response.data.total_tickets || 0;

                })
                .catch((error) => {
                    this.error = error.response?.data?.error || "An error occurred.";
                })
                .finally(() => {
                    this.showLoader = false;
                });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row mb-4">
            <div v-if="!toggleProfile" class="col-lg-4">
                <div class="card h-100 shadow-sm">
                    <div v-if="showLoader" class="card-body d-flex align-items-center justify-content-center">
                        <b-spinner large></b-spinner>
                    </div>
                    <div v-else class="card-body">
                        <h3 class="mt-3 mb-1 text-center">{{ packInfo.name || "Pack Name" }}</h3>

                        <p class="mt-2">
                            <strong>Active:</strong>
                            <span :class="packInfo.active ? 'text-success' : 'text-danger'">
                                <i
                                    :class="packInfo.active ? 'uil uil-check fa-2x text-success' : 'uil uil-times fa-2x text-danger'"></i>
                            </span>

                        </p>
                        <p class="mt-2">
                            <strong>State:</strong>
                            <span :class="packInfo.state === '1' ? 'text-success fw_bold' : 'text-danger fx-bold'">
                                {{ packInfo.state === '1' ? 'Claimable' : 'Not Available' }}
                            </span>
                        </p>

                        <p><strong>Type:</strong> {{ packInfo.type || "N/A" }}</p>
                        <p><strong>Match ID:</strong> {{ packInfo.match_id || "N/A" }}</p>
                        <p><strong>Start Time:</strong> {{ packInfo.start_time || "N/A" }}</p>
                        <p><strong>Slug:</strong> {{ packInfo.slug || "N/A" }}</p>
                        <p><strong>Season:</strong> {{ packInfo.season || "N/A" }}</p>

                    </div>
                </div>
            </div>
            <div :class="!toggleProfile ? 'col-lg-8' : 'col-lg-12'">
                <div class="card">
                    <b-tabs content-class="p-4" justified class="nav-tabs-custom">
                        <b-tab active>
                            <template #title>
                                <i class="uil uil-chart-bar font-size-20"></i>
                                <span class="d-none d-sm-inline">Totals</span>
                            </template>

                            <div v-if="showLoader" class="d-flex justify-content-center align-items-center">
                                <b-spinner large></b-spinner>
                            </div>
                            <div v-else class="row mb-4 g-4 d-flex flex-wrap">
                                <div class="col-lg-3 d-flex">
                                    <div class="card flex-grow-1">
                                        <div v-if="showLoader"
                                            class="card-body d-flex align-items-center justify-content-center">
                                            <b-spinner large></b-spinner>
                                        </div>
                                        <div v-else
                                            class="card-body text-center d-flex justify-content-center align-items-center">
                                            <div>
                                                <p class="mb-2 card-text fs-4 fw-bold text-primary">{{
                                                    formattedTotalCollectibles }}</p>
                                                <h4 class="card-title">Total Collectibles</h4>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 d-flex">
                                    <div class="card flex-grow-1">
                                        <div v-if="showLoader"
                                            class="card-body d-flex align-items-center justify-content-center">
                                            <b-spinner large></b-spinner>
                                        </div>
                                        <div v-else
                                            class="card-body text-center d-flex justify-content-center align-items-center">
                                            <div><p class="mb-2 card-text fs-4 fw-bold text-warning">{{ totalClaimed }} </p>
                                                <h4 class="card-title">Total Claimed</h4></div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 d-flex">
                                    <div class="card flex-grow-1">
                                        <div v-if="showLoader"
                                            class="card-body d-flex align-items-center justify-content-center">
                                            <b-spinner large></b-spinner>
                                        </div>
                                        <div v-else
                                            class="card-body text-center d-flex justify-content-center align-items-center">
                                            <div> <p class="mb-2 card-text fs-4 fw-bold text-success">{{ totalImagePercent }}%
                                            </p>
                                            <h4 class="card-title">Image Percent</h4></div>
                                           
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 d-flex">
                                    <div class="card flex-grow-1">
                                        <div v-if="showLoader"
                                            class="card-body d-flex align-items-center justify-content-center">
                                            <b-spinner large></b-spinner>
                                        </div>
                                        <div v-else class="card-body">
                                        <p class="mb-2 card-text fs-4 fw-bold text-center text-secondary">{{
                                                formattedTotalTickets }}</p>
                                            <h4 class="card-title text-center">Total Tickets</h4>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <p class="mb-0"><strong>Single:</strong> {{ totalSingleTickets }}</p>
                                                <p class="mb-0"><strong>Season:</strong> {{ totalSeasonTickets }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab>
              <template v-slot:title>
                <i class="uil uil-image font-size-20"></i>
              <span class="d-none d-sm-block">Images</span>
              </template>
              <div class="row">
                <TabPacksImages :pack_id="this.$route.params.pack_id" />
              </div>
            </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
    </Layout>
</template>
