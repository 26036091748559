<script>

    import Customers from '@/services/Customers';

    export default {
        components: {  },
        data() {
            return {
                packImages:[],
                error: null,
                isBusy: false,
              
            }
        },
        computed: {
      
        },
        mounted() {
            this.getPackImages()
        },
      
        methods: {

  formatDateTime(utcString) {
    if (!utcString) return 'N/A';
    const date = new Date(utcString);
    return date.toLocaleDateString(); 
  },

  formatDate(utcTimestamp) {
      const date = new Date(utcTimestamp * 1000);  
      return date.toLocaleString(); 
    },

    async getPackImages() {

  this.isBusy = true;
  try {
    const response = await Customers.getNftPackDetails(this.$route.params.pack_id)
    this.packImages = response.data.images_data; 
  } catch (error) {
    this.error = error.response?.data?.error || 'An error occurred.';
  } finally {
    this.isBusy = false;
  }
}

}
    }
</script>

   <template>
    <div class="row">
      <div class="col-12">
        <div v-if="isBusy" class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
  
        <div v-else-if="packImages.length === 0" class="text-center my-2">
          <strong>No images available.</strong>
        </div>
  
        <div v-else class="row">
          <div
            v-for="(image, imageIndex) in packImages"
            :key="imageIndex"
            class="col-md-6 col-lg-4 mb-3"
          >
          <div class="card mb-3">
            <img
              v-if="image.image_url"
              :src="image.image_url"
              alt="Pack Image"
              class="card-img-top"
            />
            <div
              v-else
              class="d-flex justify-content-center align-items-center bg-light"
              style="height: 300px;"
            >
              <strong>No Image Available</strong>
            </div>
            <div class="card-body card-footer text-center">
                    <p class="card-title">Total: {{ image.total }}</p>
                    <p class="card-title">{{ image.percentage }}%</p>   
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  